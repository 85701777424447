<template>
  <div>
    <v-main>
      <v-container v-if="false" class="fill-height" fluid style="background-color: red">
        <div class="d-flex flex-wrap align-center">
          <div class="text-h5 mr-4">Frank Demomann</div>
          <v-chip class="" color="secondary" label outlined>Unkontaktiert</v-chip>
        </div>
        <div class="mt-4 d-flex">
          <v-text-field class="mr-3" outlined label="Vorname" dense v-model="info['firstName']"></v-text-field>
          <v-text-field class="mr-3" outlined label="Nachname" dense v-model="info['lastName']"></v-text-field>
          <v-text-field class="mr-3" outlined label="E-Mail Adresse" dense
                        v-model="info['emailAddress']"></v-text-field>
          <v-text-field outlined label="Telefonnummer" dense v-model="info['phoneNumber']"></v-text-field>
        </div>
        <v-card v-if="false">
          <v-toolbar
              flat
              color="primary"
              dark
          >
            <v-toolbar-title>User Profile</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-if="false" vertical hide-slider>
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Option 1
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Option 2
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Option 3
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Option 1
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Option 2
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Option 3
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Option 1
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Option 2
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Option 3
            </v-tab>

            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac felis. Etiam feugiat lorem non
                    metus. Sed a libero.
                  </p>

                  <p>
                    Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Aenean tellus metus, bibendum
                    sed, posuere ac, mattis non, nunc. Aliquam lobortis. Aliquam lobortis. Suspendisse non nisl sit amet
                    velit hendrerit rutrum.
                  </p>

                  <p class="mb-0">
                    Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu. Pellentesque libero tortor, tincidunt
                    et, tincidunt eget, semper nec, quam. Phasellus blandit leo ut odio.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus,
                    vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam
                    libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae,
                    posuere imperdiet, leo. Nunc sed turpis.
                  </p>

                  <p>
                    Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
                    tellus, vitae iaculis lacus elit id tortor. Proin viverra, ligula sit amet ultrices semper, ligula
                    arcu tristique sapien, a accumsan nisi mauris ac eros. In hac habitasse platea dictumst. Fusce ac
                    felis sit amet ligula pharetra condimentum.
                  </p>

                  <p>
                    Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero.
                    Nam commodo suscipit quam. In consectetuer turpis ut velit. Sed cursus turpis vitae tortor. Aliquam
                    eu nunc.
                  </p>

                  <p>
                    Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt libero. Aenean viverra rhoncus
                    pede. Duis leo. Fusce fermentum odio nec arcu.
                  </p>

                  <p class="mb-0">
                    Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum
                    et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis
                    a, condimentum nec, nisi.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula
                    sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui
                    magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a,
                    auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nam at tortor in tellus interdum sagittis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac felis. Etiam feugiat lorem non
                    metus. Sed a libero.
                  </p>

                  <p>
                    Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Aenean tellus metus, bibendum
                    sed, posuere ac, mattis non, nunc. Aliquam lobortis. Aliquam lobortis. Suspendisse non nisl sit amet
                    velit hendrerit rutrum.
                  </p>

                  <p class="mb-0">
                    Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu. Pellentesque libero tortor, tincidunt
                    et, tincidunt eget, semper nec, quam. Phasellus blandit leo ut odio.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus,
                    vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam
                    libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae,
                    posuere imperdiet, leo. Nunc sed turpis.
                  </p>

                  <p>
                    Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
                    tellus, vitae iaculis lacus elit id tortor. Proin viverra, ligula sit amet ultrices semper, ligula
                    arcu tristique sapien, a accumsan nisi mauris ac eros. In hac habitasse platea dictumst. Fusce ac
                    felis sit amet ligula pharetra condimentum.
                  </p>

                  <p>
                    Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero.
                    Nam commodo suscipit quam. In consectetuer turpis ut velit. Sed cursus turpis vitae tortor. Aliquam
                    eu nunc.
                  </p>

                  <p>
                    Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt libero. Aenean viverra rhoncus
                    pede. Duis leo. Fusce fermentum odio nec arcu.
                  </p>

                  <p class="mb-0">
                    Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum
                    et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis
                    a, condimentum nec, nisi.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula
                    sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui
                    magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a,
                    auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nam at tortor in tellus interdum sagittis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac felis. Etiam feugiat lorem non
                    metus. Sed a libero.
                  </p>

                  <p>
                    Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Aenean tellus metus, bibendum
                    sed, posuere ac, mattis non, nunc. Aliquam lobortis. Aliquam lobortis. Suspendisse non nisl sit amet
                    velit hendrerit rutrum.
                  </p>

                  <p class="mb-0">
                    Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu. Pellentesque libero tortor, tincidunt
                    et, tincidunt eget, semper nec, quam. Phasellus blandit leo ut odio.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus,
                    vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam
                    libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae,
                    posuere imperdiet, leo. Nunc sed turpis.
                  </p>

                  <p>
                    Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
                    tellus, vitae iaculis lacus elit id tortor. Proin viverra, ligula sit amet ultrices semper, ligula
                    arcu tristique sapien, a accumsan nisi mauris ac eros. In hac habitasse platea dictumst. Fusce ac
                    felis sit amet ligula pharetra condimentum.
                  </p>

                  <p>
                    Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero.
                    Nam commodo suscipit quam. In consectetuer turpis ut velit. Sed cursus turpis vitae tortor. Aliquam
                    eu nunc.
                  </p>

                  <p>
                    Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt libero. Aenean viverra rhoncus
                    pede. Duis leo. Fusce fermentum odio nec arcu.
                  </p>

                  <p class="mb-0">
                    Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum
                    et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis
                    a, condimentum nec, nisi.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item transition="">
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula
                    sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui
                    magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a,
                    auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nam at tortor in tellus interdum sagittis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>

        </v-card>
        <v-row class="fill-height2" style="background-color: green">
          <v-col>asdf</v-col>
        </v-row>
      </v-container>
      <v-container v-if="info" fluid class="d-flex flex-column"
                   style="background-color: red; height: calc(100vh - 64px)">
        <v-row class="flex-grow-0" style="background-color: green">
          <v-col>Abschnitt 1</v-col>
        </v-row>
        <v-row class="flex-grow-1" style="background-color: blue">
          <v-col cols="6" style="background-color: yellow">
            Gesprächsleitfaden
          </v-col>
          <v-col cols="6" class="d-flex flex-column py-0" style="background-color: lime;">
            <v-row class="flex-grow-1" style="background-color: beige; max-height: 50%">
              <v-col style="">
                <v-text-field class="mb-4" outlined label="Vorname" dense hide-details v-model="info['firstName']"
                              @input="infoChanged('firstName')"></v-text-field>
                <v-text-field class="mb-4" outlined label="Nachname" dense hide-details v-model="info['lastName']"
                              @input="infoChanged('lastName')"></v-text-field>
                <v-text-field class="mb-4" outlined label="E-Mail Adresse" dense hide-details
                              v-model="info['emailAddress']" @input="infoChanged('emailAddress')"></v-text-field>
                <v-text-field class="mb-4" outlined label="Telefonnummer" dense hide-details
                              v-model="info['phoneNumber']" @input="infoChanged('phoneNumber')"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="flex-grow-1" style="background-color: violet">
              <v-col>col 2</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";

export default {
  name: "MemberView",
  props: ["userId"],
  data: () => ({
    propertiesToPush: {}
  }),
  computed: {
    info() {
      return this.$store.state.cachedData["personal-info/" + this.userId];
    }
  },
  mounted() {
    this.$store.dispatch("getRequest", ["personal-info/" + this.userId, this.getPersonalInfoCallback]);
  },
  methods: {
    getPersonalInfoCallback(infoList) {
      let personalInfo = {};
      for (let info of infoList) {
        personalInfo[info.property] = info.value;
      }
      Vue.set(this.$store.state.cachedData, "personal-info/" + this.userId, personalInfo);
    },
    infoChanged(property) {
      this.propertiesToPush[property] = this.info[property].trim();
      this.schedulePropertyPush();

      let value = this.info[property];
      let info = { property: property, value: value };
      //this.$store.dispatch("postRequest", ["personal-info/" + this.userId, info]);

    },
    schedulePropertyPush: _.debounce(function() {
      this.$store.dispatch("postRequest", ["personal-info/" + this.userId, this.propertiesToPush]);
      this.propertiesToPush = {};
    }, 500)
  }
};
</script>

<style scoped>

</style>